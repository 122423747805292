import { axios } from "@/utils/request";

// 获取报名阶段
export function getStage(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/bmjd/list",
    method: "get",
    params: params,
  });
}

// 获取分班结果
export function getClassInfo(params = {}) {
  return axios({
    url: "fast-imagine/mobile/yw/getClassInfo",
    method: "post",
    data: params,
  });
}

// 注册 第一步验证
export function identityTest(params = {}) {
  return axios({
    // url: "/fast-imagine/mobile/login/identityTest",
    url: "/fast-imagine/mobile/login/registerAndLogin",
    method: "post",
    data: params,
  });
}
// 注册
export function register(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/regInfoTb",
    // url: "/fast-imagine/mobile/login/register",
    method: "post",
    data: params,
  });
}
// 未核验通过  再次填报
export function registerTwo(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/yw/register",
    method: "post",
    data: params,
  });
}
// 再次填报开关
export function btnKg(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/yw/register/btnKg",
    method: "post",
    data: params,
  });
}
// 再次填报 剩余次数
export function changeCount(params = {}) {
  return axios({
    url: "/fast-imagine/yw/ywRegInfo/surplusChangeTimes",
    method: "get",
    params: params,
  });
}

// 注册--验证码
export function registerCode(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/send/verification/code",
    method: "get",
    params: params,
  });
}

// 注册--派出所列表
export function getPcsList(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/getPcsList",
    method: "get",
    params: params,
  });
}

// 注册--获取街道
export function getStreet(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/getStreetList",
    method: "get",
    params: params,
  });
}

// 注册--获取社区
export function getSq(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/getsqList",
    method: "get",
    params: params,
  });
}

// 登录
export function userLogin(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/login",
    method: "post",
    data: params,
  });
}

// 获取个人信息
export function getUserInfo(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/yw/getUserInfo",
    method: "get",
    data: params,
  });
}

// 获取各阶段状态
export function getStatus(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/yw/getStatus",
    method: "get",
    data: params,
  });
}

// 录取通知书信息
export function getLqInfo(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/yw/getLqInfo",
    method: "get",
    data: params,
  });
}

// 退出登录
export function logOut(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/loginOut",
    method: "get",
    data: params,
  });
}

// 找回密码
export function forgotPwd(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/reset/password",
    method: "post",
    data: params,
  });
}
// 找回密码--验证码
export function forgotCode(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/login/send/reset/verification/code",
    method: "post",
    data: params,
  });
}
//批量查询字典
export function getListBySignList(params = {}) {
  return axios({
    url: "/fast-imagine/basis/dictionary/getListBySignList",
    method: "get",
    params,
  });
}
//查询是否具有吕梁一小选报资格
export function getintentioncheck(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/special/intention/check",
    method: "get",
    params,
  });
}
// 选报吕梁一小
export function getintentionsubmit(params = {}) {
  return axios({
    url: "/fast-imagine/mobile/special/intention/submit",
    method: "post",
    data: params,
  });
}
