import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router/index'
import {
  VueAxios
} from './axios'
import notification from 'ant-design-vue/es/notification'

const codeMessage = {
  // 200: '服务器成功返回请求的数据。',
  // 201: '新建或修改数据成功。',
  // 202: '一个请求已经进入后台排队（异步任务）。',
  // 204: '删除数据成功。',
  // 400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  // 401: '用户没有权限（令牌、用户名、密码错误）。',
  // 403: '用户得到授权，但是访问是被禁止的。',
  // 404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  // 406: '请求的格式不可得。',
  // 410: '请求的资源被永久删除，且不会再得到的。',
  // 422: '当创建一个对象时，发生一个验证错误。',
  // 500: '服务器发生错误，请检查服务器。',
  // 502: '网关错误。',
  // 503: '服务不可用，服务器暂时过载或维护。',
  // 504: '网关超时。',
  500: '会话失效，请重新登录'
}

// 创建 axios 实例
const service = axios.create({
  baseURL: '/', // api base_url
  timeout: 5 * 60 * 1000 // 请求超时时间
})
const err = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status == 403) {
      notification.error({
        message: '提示',
        description: '服务器异常，请稍后再试！'
      })
    }

    if (error.response.status == 480) {
      const data = error.response.data
      const {
        code,
        description
      } = data
      if (code == '100002') {
        localStorage.removeItem("user");
        setTimeout(() => {
          // router.replace('/login')
          router.replace('/enter')
        }, 1500)
      }
      notification.error({
        message: '提示',
        description: '服务器异常，请稍后再试！'
      })
    }

    if (error.response.status === 401) {
      notification.error({
        message: '提示',
        description: '登录已过期，请重新登录！'
      })
      localStorage.removeItem("user");
        setTimeout(() => {
          // router.replace('/login')
          router.replace('/enter')
        }, 1500)
    }

    if (error.response.status === 500 || error.response.status === 502 || error.response.status === 503 || error.response.status === 504) {
      notification.error({
        message: '提示',
        description: '服务器异常，请稍后再试！'
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {

  if (process.env.NODE_ENV === 'production') {
    let { url } = config;

    config.url = url;  
  }

  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
